import PaginationUI from "@/components/UI/PaginationUI.vue";
import NavbarUI from "@/components/UI/NavbarUI.vue";
import InputUI from "@/components/UI/InputUI.vue";
import ButtonUI from "@/components/UI/ButtonUI.vue";
import DialogUI from "@/components/UI/DialogUI.vue";
import CustomSelect from "@/components/UI/Select.vue";

export default [
    PaginationUI,
    NavbarUI,
    InputUI,
    ButtonUI,
    DialogUI,
    CustomSelect
];