import axios from 'axios';

class AuthService {
    login(user) {
        console.log(123);
        return axios
            .post(process.env.VUE_APP_BASE_URL + 'login', {
                login: user.login,
                password: user.password
            })
            .then(response => {
                if (response.data.token) {
                    localStorage.setItem('user', JSON.stringify(response.data));
                }

                return response.data;
            });
    }

    logout() {
        localStorage.removeItem('user');
    }
}

export default new AuthService();
