<template>
  <div class="container">
    <!-- Side Menu -->
    <div class="side" :class="slideClass">
      <NavbarUI></NavbarUI>
      <nav class="menu menu__bot">
        <ul class="menu__list">
          <li>
            <a href="https://t.me/thesuport" target="_blank">Поддержка</a>
            <img v-bind:src="require('@/assets/img/support.svg')" />
          </li>
          <li>
            <a @click="slide" class="menu__toggle" href="#">Свернуть</a>
            <img v-bind:src="require('@/assets/img/toggle.svg')" />
          </li>
        </ul>
      </nav>
    </div>

    <!-- Content -->
    <div class="content">
      <router-view/>
    </div>
  </div>
</template>
<script>
  import NavbarUI from "@/components/UI/NavbarUI.vue";
  export default {
    name: 'main-layout',
    components: {
      NavbarUI
    },
    data: ()=>({
      isActive: false,
      slideClass: ''
    }),
    methods: {
      slide(){
        this.isActive = !this.isActive;

        if(this.isActive){
          this.slideClass = ' side_collapse';
        } else {
          this.slideClass = '';
        }
      }
    }
  }
</script>