<script>
export default {
  name: 'button-ui',
  props: {
    buttonClass: String
  }
}
</script>

<template>
  <button :class="buttonClass">
    <slot></slot>
  </button>
</template>

<style scoped>

</style>