<script>
export default {
  name: 'dialog-ui',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    width: Number,
    height: Number,
    padding: Number,
    background: String,
    close: Boolean
  },
  methods:
  {
    hideDialog(){
      this.$emit('update:show', false)
    }
  },
  computed: {
    dialog() {
      return {
        "min-width": `${this.width}px`,
        "min-height": `${this.height}px`,
        "padding": `${this.padding}px`,
        "background": (typeof this.background !== 'undefined') ? `${this.background}` : 'white'
      };
    }
  }
}
</script>

<template>
  <div class="dialog" v-if="show" @click.stop="hideDialog">
    <div class="dialog__block" :style="dialog">
      <div v-if="close" class="dialog__close"><img src="@/assets/img/modal_close.svg"  alt="close"/></div>
      <div @click.stop class="dialog__content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>