<!--NEW 22-03-24 START-->
<template>
  <div class="custom-select" ref="customSelect" @click="toggleOptions">
    <div class="selected-option">{{ selectedOption ? selectedOption.label : 'Все' }}</div>
    <span class="dropdown-icon">
      <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L5.5 6L10 0.999999" stroke="#5F636C" stroke-width="2"/>
      </svg>
    </span>
    <div class="options" v-show="showOptions" @click.stop>
      <input type="text" class="search-field" v-model="searchTerm" @click.stop placeholder="Поиск...">
      <div v-if="searchTerm.length === 0" @click="selectOption(null)">Все</div>
      <div
          v-for="option in filteredOptions"
          :key="option.value"
          @click="selectOption(option)"
      >
        {{ option.label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'custom-select',
  props: ['options', 'value', 'onChange'],
  data() {
    return {
      searchTerm: '',
      selectedOption: null,
      showOptions: false,
      rawOptions: [
        { label: 'Option 1', value: 'option1' },
        { label: 'Option 2', value: 'option2' },
        { label: 'Option 3', value: 'option3' },
        // Add more options as needed
      ]
    };
  },
  computed: {
    filteredOptions() {
      return (this.options || []).filter(option => {
        return option.label.toLowerCase().includes(this.searchTerm.toLowerCase());
      });
    }
  },
  methods: {
    toggleOptions() {
      this.showOptions = !this.showOptions;
    },
    selectOption(option) {
      this.selectedOption = option;
      this.showOptions = false;
      this.searchTerm = ''; // Reset search term after selection
      this.$emit('onselect', option)
    },
    handleClickOutside(event) {
      if (!this.$refs.customSelect?.contains(event.target)) {
        this.showOptions = false;
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }
};
</script>

<style scoped>
.custom-select {
  position: relative;
  width: 270px;
  cursor: pointer;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 7px 10px;
  background: #fff;
  border: 1px solid #C6C8D3;
}

.search-field {
  padding: 7px 10px;
  background: #fff;
  border: 1px solid #C6C8D3;
  color: #5F636C;
  font-family: 'PT Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.12px;
  width: 100%;
}

.dropdown-icon {

}

.selected-option {
  flex: 1 1 0;
  color: #5F636C;
  font-family: 'PT Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.12px;
}

.options {
  position: absolute;
  top: 100%;
  max-height: 300px;
  overflow-y: auto;
  left: 0;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #ccc;
  width: 100%;
  color: black;
}

.options div {
  padding: 5px 10px;
  cursor: pointer;
}

.options div:hover {
  background-color: #f0f0f0;
}
</style>
<!--NEW 22-03-24 END -->
