<template>
  <input
      :value="modelValue"
      @input="updateInput"
      :class="inputClass"
      :type="inputType"
      :placeholder="inputPlaceholder"
  >
</template>

<script>
export default {
  name: 'input-ui',
  props: {
    modelValue: [String, Number],
    inputClass: String,
    inputType: String,
    inputPlaceholder: String,
  },
  methods: {
    updateInput(event) {
      this.$emit('update:modelValue', event.target.value)
    }
  }
}
</script>

<style scoped>

</style>
